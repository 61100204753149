(function () {
    function initDataLayer(response) {
        if (response === 'We got your number! We will call you soon.') {
            dataLayer.push({
                'event': 'Kontakt'
            });
        }
    }
    $('.submit').on('click', function (e) {
        e.preventDefault();
        var userMsg = $('.userMsg');
        var phone = $('.inputPhone');

        if (phone.val().length === 0) {
            userMsg.text('The field can not be empty.').slideDown();
            return;
        }

        $.ajax({
            type: "POST",
            url: "phpmailer/email.php",
            data: {
                phone: phone.val()
            },
            success: function (response) {
                userMsg.text(response).slideDown();
                phone.val('');
                initDataLayer(response);
            },
            error: function () {
                userMsg.text('Email could not be sent.');
            }
        });

    });
}(jQuery));