(function ($) {
    $('.ad-container').css('display', 'block');

    if (viewport().width >= 768) {
        $('.ad-container').detach().insertAfter('.form-container');
    }

    $(window).resize(function () {
        if ($('.ad-container').length !== 0) {
            if (viewport().width >= 768) {
                if ($('.form-container').next().length === 0)
                    $('.ad-container').detach().insertAfter('.form-container');

            } else {
                if ($('.ad-container').next().length === 0)
                    $('.form-container').detach().insertAfter('.ad-container');
            }
        }
    });

    function viewport() {
        var e = window,
            a = 'inner';
        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return {
            width: e[a + 'Width'],
            height: e[a + 'Height']
        };
    }
})(jQuery);