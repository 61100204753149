(function ($) {
    function calcHeight() {
        var $element = $('#boxes-list figcaption');

        $element.css({
            top: 'auto',
            height: 'auto'
        });

        $element.each(function () {
            var figcaptionHeight = $(this).outerHeight();
            $(this).css({
                top: 0,
                height: figcaptionHeight
            });
        });
    }

    calcHeight();

    $(window).on('resize', function () {
        calcHeight();
    });
})(jQuery);